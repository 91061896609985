<template>
    <div class="formContainer" @dragover.prevent="dragOver" @dragleave="dragLeave" @drop.prevent="handleDrop">
        <div class="phzh-header">
            <div class="container">
                <div class="row">
                    <div class="phzh-logo-block col-lg-push-5 col-lg-5 col-md-push-4 col-md-6 col-sm-4 col-xs-12">
                        <img src="https://api.phzh.ch/styles/img/phzh_logo_ganz.svg" title="PH Zürich" alt="PH Zürich">
                    </div>
                    <div class="phzh-wordmark-block col-lg-push-9 col-lg-5 col-md-push-8 col-md-6 col-md-push-12 col-sm-6 col-xs-12"
                         style="text-align:right;">
                        <img src="https://api.phzh.ch/styles/img/phzh_logo_text.svg" title="PH Zürich" alt="PH Zürich">
                    </div>
                </div>
            </div>
        </div>

        <div class="phzh-page-content">
            <div class="phzh-main-content">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-push-5 col-lg-14 col-md-push-4 col-md-16 col-sm-24 col-xs-24">
                            <!-- intro text -->
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-push-5 col-lg-14 col-md-push-4 col-md-16 col-sm-24 col-xs-24">

                                <h1 v-html="$t('Verification.Header')"></h1>
                                <p v-html="$t('Verification.Description')"></p>

                                <div id="app" class="verification-app">
                                    <CertifactionVerification ref="verify"
                                                              :demo="false"
                                                              :pdf-wasm-url="pdfReaderWasmUrl"
                                                              :pdfjs-worker-src="pdfjsWorkerSrc"
                                                              :pdfjs-c-map-url="pdfjsCMapUrl"
                                                              :provider-url="providerUrl"
                                                              :legacy-contract-address="legacyContractAddress"
                                                              :legacy-contract-fallback-addresses="legacyContractFallbackAddresses"
                                                              :claim-contract-address="claimContractAddress"
                                                              :accepted-issuer-key="acceptedIssuerKey"
                                                              :certifaction-api-url="certifactionApiUrl"
                                                              :offchain-verifier="CertifactionOffchainVerifier" />
                                </div>

                            </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-push-5 col-lg-14 col-md-push-4 col-md-16 col-sm-24 col-xs-24">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CertifactionOffchainVerifier from './lib/CertifactionOffchainVerifier'
import pdfjsWorkerSrc from '@certifaction/pdfjs/dist/pdfjs.worker.min'
import { CertifactionVerification } from '@certifaction/verification-vue-component'

export default {
    name: 'App',
    components: {
        CertifactionVerification
    },
    data() {
        const pdfWasmVersion = process.env.VUE_APP_PDF_WASM_VERSION
        const cacheBuster = process.env.VUE_APP_CACHE_BUSTER
        const cdnBaseUrl = process.env.VUE_APP_CERTIFACTION_CDN_BASE_URL

        return {
            pdfReaderWasmUrl: new URL(`/wasm/pdf-${pdfWasmVersion}/pdf_reader.wasm?t=${cacheBuster}`, cdnBaseUrl),
            pdfjsWorkerSrc,
            pdfjsCMapUrl: 'pdf/cmaps/',
            providerUrl: process.env.VUE_APP_PROVIDER_URL,
            legacyContractAddress: process.env.VUE_APP_LEGACY_CONTRACT_ADDRESS,
            claimContractAddress: process.env.VUE_APP_CLAIM_CONTRACT_ADDRESS,
            acceptedIssuerKey: process.env.VUE_APP_ACCEPTED_ISSUER_KEY,
            certifactionApiUrl: process.env.VUE_APP_CERTIFACTION_API_URL,
            CertifactionOffchainVerifier
        }
    },
    computed: {
        locale() {
            const searchParams = new URLSearchParams(window.location.search)
            return searchParams.has('lang') ? searchParams.get('lang') : 'de'
        },
        legacyContractFallbackAddresses() {
            const fallbackAddresses = process.env.VUE_APP_LEGACY_CONTRACT_FALLBACK_ADDRESSES
            return (fallbackAddresses && fallbackAddresses.length > 0) ? fallbackAddresses.split(',') : []
        }
    },
    mounted() {
        this.$i18n.locale = this.locale
    },
    methods: {
        async dragOver() {
            await this.$refs.verify.dragOver()
        },
        dragLeave() {
            this.$refs.verify.dragLeave()
        },
        handleDrop(e) {
            this.$refs.verify.handleDrop(e)
        }
    }
}
</script>

<style lang="scss">
@import "assets/scss/app";
</style>
